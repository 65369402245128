@use "sass:color";

.svg-font-color svg > path {
  fill: var(--ifm-font-color-base);
}

.spanButton {
  background: #ffffff;
  padding: 5px;
  border-radius: 5px;
  border-width:1px;
  border-color: #cccccc;
  cursor: pointer;
}

.spanButton:hover {
  background: #f0f0f0;
}

.highlightTable {
  display: flex;
  flex-direction: column;

  [data-rfd-droppable-id="droppable"] {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    .row {
      padding: 5px 0;
    }
  }

  .row {
    align-items: center;
  }
}

.queryBuilder {
  min-width: 420px;

  .ruleGroup {
    background: none;
    border: none;
  }

  .rule-fields,
  .rule-operators,
  .rule-value {
    flex: 1;
  }

  .rule-value-custom {
    width: 173.5px;
  }

  .rule-field-selector-custom {
    width: 200.5px;
  }

  .rule-operator-selector-custom {
    width: 150.5px;
  }

  .rule-value-custom-checkbox {
    label > span > span:after {
      left: 38%;
    }
  }

  .rule-remove {
    flex: 0 0 20px;
  }

  .form-select,
  .form-control {
    min-height: 38px;
  }

  .ant-checkbox-wrapper::after {
    display: none;
  }

  .ant-checkbox-inner {
    width: 32px;
    height: 32px;
  }
}

// Styles for when "Use validation" option is selected
.validateQuery {
  .queryBuilder {
    // Invalid groups
    .ruleGroup.queryBuilder-invalid {
      background-color: #66339966; // transluscent rebeccapurple

      // Bold the + Rule button if the group has no child rules or groups
      .ruleGroup-addRule {
        font-weight: bold !important;
      }

      // Message to user about empty groups
      & > .ruleGroup-header::after {
        content: "Empty groups are considered invalid. Avoid them by using addRuleToNewGroups.";
        color: white;
      }
    }

    // Invalid rules
    .rule.queryBuilder-invalid {
      // Purple background for empty text fields
      .rule-value {
        background-color: #66339966; // transluscent rebeccapurple
        &::placeholder {
          color: color.scale(rebeccapurple, $lightness: -30%);
        }
      }
    }
  }
}

// Styles for when "Use validation" option is selected (dark theme variations)
html[data-theme="dark"] {
  .validateQuery {
    .queryBuilder {
      .rule.queryBuilder-invalid {
        .rule-value {
          &::placeholder {
            color: color.scale(rebeccapurple, $lightness: 30%);
          }
        }
      }
    }
  }
}

// Styles for when "Justified layout" option is selected
.justifiedLayout {
  .queryBuilder {
    // Push the clone, lock, and remove buttons to the right edge
    .ruleGroup-addGroup + button.ruleGroup-cloneGroup,
    .ruleGroup-addGroup + button.ruleGroup-lock,
    .ruleGroup-addGroup + button.ruleGroup-remove,
    .rule-operators + button.rule-cloneRule,
    .rule-operators + button.rule-lock,
    .rule-operators + button.rule-remove,
    .rule-value + button.rule-cloneRule,
    .rule-value + button.rule-lock,
    .rule-value + button.rule-remove,
    .control + button.rule-cloneRule,
    .control + button.rule-lock,
    .control + button.rule-remove,
    .chakra-select__wrapper + button.rule-cloneRule,
    .chakra-select__wrapper + button.rule-lock,
    .chakra-select__wrapper + button.rule-remove {
      margin-left: auto !important;
    }
  }
}