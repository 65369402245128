.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  
  table {
    width: 100%;
    border-collapse: collapse;
    
    tr:last-child td {
      border-bottom: none;
    }
    
    td {
      padding: 12px 0;
      vertical-align: middle;
    }
    
    th {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      text-align: left;
      padding: 0 0 12px;
    }
    
    th:first-child,
    td:first-child {
      font-weight: 700;
      width: 175px !important;
    }
    
    th:not(:first-child),
    td:not(:first-child) {
      text-align: left;
      padding-left: 15px;
      width: 20%;
    }
    
    th:last-child,
    td:last-child {
      width: 100px;
      text-align: right;
    }
  }
  
  /* Date and reason inline container */
  .date-reason-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    
    .date-picker-wrapper {
      width: 100%;
    }
    
    .reason-select-wrapper {
      width: 100%;
      
      label {
        display: block;
        font-weight: 600;
        margin-bottom: 4px;
      }
    }
  }
  
  /* Comments section */
  .comments-section {
    margin-top: 16px;
    
    label {
      display: block;
      font-weight: 700;
      margin-bottom: 8px;
    }
    
    textarea {
      width: 100%;
      min-height: 80px;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
}