.funnel-chart-widget {
  position: relative;
  
  .funnel-chart-container {
    position: relative;
    height: 400px; // Fixed height for the entire container
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    
    &:hover {
      .plot-controls {
        opacity: 1;
      }
    }
  }
  
  .chart-wrapper {
    //width: 100%;
    height: 100%; // Take full height of parent
    position: relative;
  }
  
  .chart-container {
    //width: 100%;
    height: 100%; // This is crucial - ResponsiveFunnel needs a container with defined height
    position: relative;
  }
  
  .plot-controls {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 8px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    
    &.show {
      opacity: 1;
    }
    
    .control-button {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.9);
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s ease;
      color: #555;
      padding: 0;
      
      &:hover {
        background-color: #f0f0f0;
        transform: scale(1.05);
      }
      
      &:active {
        transform: scale(0.95);
      }
      
      i {
        font-size: 14px;
      }
      
      &.view-toggle {
        background-color: rgba(67, 97, 238, 0.1);
        color: #4361ee;
      }
      
      &.rotation-toggle {
        background-color: rgba(60, 160, 240, 0.1);
        color: #3ca0f0;
      }
      
      &.funnel-mode-toggle {
        background-color: rgba(94, 96, 206, 0.1);
        color: #5e60ce;
      }
    }
  }
  
  .funnel-tooltip {
    background: white;
    padding: 9px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    
    strong {
      display: block;
      margin-bottom: 4px;
      font-size: 13px;
    }
  }
  
  // Loading state and empty state styles
  .table-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .msgBox1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  }
}