.titleWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;

  span {
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
  }
}

.connectionLogoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 70px;
  flex-direction: column;
  padding: 10px;

  a {
    width: 200px;
    max-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 100%; // make sure the image fits within the container
    max-height: 100%;
    object-fit: contain; // prevent transforming original image proportions
  }
}

.divider {
  margin: 5px 0;
  border-bottom: 4px solid #111; // obsidian color
}

.connectionButtonWrapper {
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  a {
    text-decoration: none;
    width: 60%;
    text-align: center;

    &[data-action='manage'] {
      background-color: #3c0;
      color: #fff;
    }

    &[data-action='reconnect'] {
      background-color: #fc3;
      color: #fff;
    }

    &[data-action='connect'] {
      background-color: #fc3;
      color: #fff;
    }

    &[data-action='upgrade'] {
      background-color: #fc3;
      color: #fff;
    }

    &[data-action='comingSoon'] {
      background-color: #ccc;
      color: #111;
    }
  }
}
