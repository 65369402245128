.jobName{
  background: #EEEEEE;
  padding: 10px;
  padding-left: 20px;
  font-weight: 600;
}

.jobBlockInfo{
  padding: 10px;
  border: 0px !important;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  background-color: #EEEEEE !important;
}

.updateFieldLabel{
  font-weight: 600;
  margin-bottom: 5px;
  vertical-align: top !important;
  padding-top: 5px !important;
}

.newJobNumberInputSection{
  display: inline-block;
}

.jobNumberInput{
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 100px;
}